import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'navigation', loadChildren: './navigation/navigation.module#NavigationModuleModule', canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '/login' }
];

export const appRoutingModule = RouterModule.forRoot(routes);