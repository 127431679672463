import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class CommunicationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public userData;
    public isDisplaySubModel;
    public userRole;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    createNews(newsData: any) {
        return this.http.post<any>(`${environment.apiUrl}/news/create`, newsData)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    createUser(userData: object) {
        return this.http.post<any>(`${environment.apiUrl}/users/create`, userData)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    deleteUser(id: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/delete/${id}`, {})
            .pipe(map(data => {
                return data;
            }), catchError(e =>
                this.throwError(e))
            );
    }
    updateUserDetails(userDetails: any, id: any) {
        return this.http.post<any>(`${environment.apiUrl}/users/update/${id}`, userDetails)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    getParticularUserDetails(id: any) {
        return this.http.get<any>(`${environment.apiUrl}/users/getuser/${id}`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    getUserDetails() {
        return this.http.get<any>(`${environment.apiUrl}/users/getallusers`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    getNews(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/news/getnews/${id}`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    getAllNews() {
        return this.http.get<any>(`${environment.apiUrl}/news/getallnews`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    getAllNewsAdmin(roleData) {
        const brand = roleData.brand.toLowerCase();
        return this.http.get<any>(`${environment.apiUrl}/news/getallnewsbybrand/${brand}/${roleData.role}`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    updateNews(newsData: any, id: any) {
        return this.http.post<any>(`${environment.apiUrl}/news/update/${id}`, newsData)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }
    deleteNews(id: string) {
        return this.http.post<any>(`${environment.apiUrl}/news/delete/${id}`, {})
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }

    searchNews(userData:any,word: string) {
        const brand = userData.brand.toLowerCase();
        return this.http.get<any>(`${environment.apiUrl}/news/getsearch/${brand}/${userData.role}/${word}`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }

    searchUser(word: string) {
       // const brand = userData.brand.toLowerCase();
        return this.http.get<any>(`${environment.apiUrl}/users/getsearch/${word}`)
        //return this.http.get<any>(`https://localhost:44370/api/users/getsearch/ash`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }

    GetAllUsers(){
        return this.http.get<any>(`${environment.apiUrl}/users/getsearch/`)
        //return this.http.get<any>(`https://localhost:44370/api/users/getsearch/ash`)
            .pipe(map(data => {
                return data;
            }), catchError(e => this.throwError(e))
            );
    }

    throwError(e) {
       // console.log(e)
        alert(e.error);
        return e;
    }
}