import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { CommunicationService } from '../_services/communication.services';


@Component({ 
    templateUrl: 'login.component.html',
    styleUrls: ['./login-page.component.scss']
 })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    active1 :boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private communicationServices: CommunicationService,
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    showhide1() {
        this.active1 = !this.active1;
      }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                   // this.loading = false;
                    this.communicationServices.userData = data;
                    this.router.navigate(['/navigation']);
                },
                error => {
                    if(error == "Not Found"){
                        this.error = "User is deactivated!!";
                    } else {
                        this.error = "Invalid Username or Password!!";
                    }                   
                    this.loading = false;
                });
    }
    closeModel() {
        for (var name in this.loginForm.controls) {
            this.loginForm.controls[name].setValue('');
          }
    } 
}
