// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://localhost:4000'
      //apiUrl: 'http://ec2-3-15-199-135.us-east-2.compute.amazonaws.com:444/api'
    //apiUrl: 'http://ec2-3-15-199-135.us-east-2.compute.amazonaws.com/newsapp/api'
   // apiUrl: 'http://18.188.65.24:444/api'
   // apiUrl: 'http://18.188.65.24/newsapp/api'
    //apiUrl: 'http://52.77.240.72/newsapp/api'
  //apiUrl: 'http://18.139.155.215/newsapp/api'
    apiUrl: 'https://www.avt-mobileapp.com/newsapp/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
