import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    getCurrentUserValue() {
        return this.currentUserSubject.value;
    }
    login(useremail: string, pwd: string) {
        let userData = {
            "email": useremail,
            "password": pwd,
        }
       return this.http.post<any>(`${environment.apiUrl}/users/login`, userData)
            .pipe(map(data => {
                let user = {
                    id: data.id,
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password,
                    token: 'fake-jwt-token',
                    role: data.role,
                    mobileNo: data.mobileNo,
                    brand:data.brand,
                    createdDate:data.createdDate,
                    updatedDate:data.updatedDate,
                    status:data.status
                }
                let sessionData = {
                    id: data.id,
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    role:data.role,
                    brand:data.brand,
                    token: 'fake-jwt-token', 
                }
                sessionStorage.setItem('currentUser', JSON.stringify(sessionData));
                this.currentUserSubject.next(user);
                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}